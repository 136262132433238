import React from "react";

import { Route } from "react-router-dom";

import AuthLayout from "../components/layout/AuthLayout";

const AuthRoute = ({ component: Component, layout: Layout, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) =>
                <AuthLayout {...rest}>
                    <Component {...props} />
                </AuthLayout>
            }
        />
    );
};

export default AuthRoute;
