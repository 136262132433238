import React, {useEffect, useState} from 'react';
import {
    CardElement,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js';
import HTTP from "../components/utils/HTTP";
import UserClassified from "../components/UserClassified";
import { useHistory } from "react-router-dom";

const Sponsored = (props) => {
    const history = useHistory();
    const stripe = useStripe();
    const elements = useElements();
    const [classified,setClassified] = useState(null)
    const [loading,setLoading] = useState(false)

    useEffect(() => {
        HTTP.get('/classified/get/'+props.match.params.id).then((response)=>{
            response.data.data.sponsored = true
            setClassified(response.data.data);
        });
    },[]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true)
        const {error, paymentMethod} = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement),
        });

        if (paymentMethod) {
            HTTP.post('/classified/charge',{classified:props.match.params.id,payment_method:paymentMethod.id}).then((response)=>{
                setLoading(false)
                history.push("/account")
            });
        }else {
            setLoading(false)
        }
    };


    return(
        <div className="row">
            <div className="col-md-6 col-sm-12 h-100">
                <div className="shadow-sm rounded overflow-hidden mb-3">
                    <div className="p-4 bg-white">
                        <form onSubmit={handleSubmit}>
                            <CardElement className="mt-4"/>
                            <div className="text-center">
                                {loading ?
                                    <a className="btn btn-success mt-4 align-self-center text-white">
                                        <div className="spinner-border spinner-border-sm" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div> Processing</a>
                                    :
                                    <button className="btn btn-success mt-4 align-self-center" type="submit" disabled={!stripe}>
                                        Pay $20.00
                                    </button>
                                }
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="col-md-6 col-sm-12 h-100">
                <div className="shadow-sm rounded overflow-hidden mb-3">
                    <div className="p-4 bg-white">
                        <h5 className="mb-2 text-gray-900">Sponsored classified</h5>
                        {classified &&
                        <UserClassified
                            classified={classified}
                            removecol={true}
                            />
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Sponsored;