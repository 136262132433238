import React from 'react';
import logo from '../assets/img/logo.png';
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import Clock from 'react-live-clock';

const Header = () => {
    const history = useHistory();

    const settings = useSelector(
        state => state.settings
    );

    const logoClick = () => {
        if (navigator.userAgent.indexOf('Mobile/') > 0 ) {
            window.location.href = '//app.hebrewnews.com/';
        }else {
            window.location.href ='//hebrewnews.com/';
        }
    }

    return(
        <div className="header-bg">
        <div className="row justify-content-center">
            <div className="col header">
                <div className="row">
                    <div className="col mobileHide">
                        <nobr><span className="headerDate">06.16.2021</span> <span className="headerDay">WED</span></nobr>
                    </div>
                    <div className="col-6 mobileHide">
                        <div className="row mt-3 justify-content-center">
                            <div className="col-auto">
                                <span className="headerDay"><Clock format={'HH:mm'} ticking={true} timezone={'America/New_York'} /> NY</span>
                            </div>
                            <div className="col-auto">
                                <span className="headerDay"><Clock format={'HH:mm'} ticking={true} timezone={'US/Pacific'} /> LA</span>
                            </div>
                            <div className="col-auto">
                                <span className="headerDay"><Clock format={'HH:mm'} ticking={true} timezone={'Asia/Jerusalem'} /> TLV</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md col-sm-4">
                        <img onClick={logoClick} src={logo} className="img-fluid mobilelogo" alt="logo"/>
                    </div>
                </div>
            </div>
        </div>
        </div>
    )
}

export default Header;
