import React from 'react';
import {Link} from "react-router-dom";
import placeholder from '../assets/img/placeholder.png';

const ClassifiedInList = (props) => {
    return(
        <div className={"col-xl-"+(props.columns?props.columns:"3")+" col-sm-6 pt-2 pb-2"} key={"classifiedpreview_"+props.classified.id}>
            <div className="custom-card shadow-sm" style={{border:props.classified.sponsored?"solid 2px gray":""}}>
                {props.classified.image ?
                <div className="custom-card-image">
                    <Link to={"/classified/"+props.classified.id}>
                        <img className="img-fluid item-img" src={props.classified.image} alt="classified"/>
                    </Link>
                </div>
                    :
                    <div className="custom-card-image">
                        <Link to={"/classified/"+props.classified.id}>
                            <img className="img-fluid item-img" src={placeholder} alt="classified"/>
                            {props.classified.jobtype &&
                            <div style={{position: "relative", top: "-100px", color: "gray", fontSize: "30px"}}
                                 className="text-center">
                                {props.classified.jobtype}
                            </div>
                            }
                        </Link>
                    </div>
                }
                <div className="p-3 pt-4">
                    <div className="custom-card-body text-right">
                        <h6 className="color-red">{props.classified.companyname}</h6>
                        <h6 className="mb-3 rtl"><Link className="text-gray-900 rtl text-right" to={"/classified/"+props.classified.id}>{props.classified.titlehebrew}</Link></h6>
                    </div>
                    <div className="custom-card-footer d-flex align-items-center">
                                    <span className="text-danger rtl"> {props.classified.category.titlehebrew}</span><Link
                        className="btn btn-sm btn-white ml-auto" to={"/classified/"+props.classified.id}>View details</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ClassifiedInList;
