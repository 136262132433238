import {SET_SETTINGS,SET_LOCATIONS,SET_CATEGORIES,SET_USER,REMOVE_USER} from "../../constants/actions";

const initialState = {
    settings : {
        bgimage:null,
        titletext1:null,
        titletext2:null,
        insearchtext:null,
        titletext1color:null,
        titletext2color:null,
        topbarcolor:null,
        lastclassifiedsnumber:null
    },
    locations : [],
    categories : [],
    user : false
};

function rootReducer(state = initialState, action) {
    if (action.type === SET_SETTINGS) {
        return Object.assign({}, state, {
            settings: action.payload
        });
    }
    if (action.type === SET_LOCATIONS) {
        return Object.assign({}, state, {
            locations: action.payload
        });
    }
    if (action.type === SET_CATEGORIES) {
        return Object.assign({}, state, {
            categories: action.payload
        });
    }
    if (action.type === SET_USER) {
        if (action.payload) {
            localStorage.setItem('user', JSON.stringify(action.payload));
            localStorage.setItem('jwt',action.payload.token);
        }else if (localStorage.getItem('user')){
            action.payload = JSON.parse(localStorage.getItem('user'));
        }

        return Object.assign({}, state, {
            user: action.payload
        });
    }
    if (action.type === REMOVE_USER) {
        localStorage.removeItem('user');
        localStorage.removeItem('jwt');
        return Object.assign({}, state, {
            user: null
        });
    }
    return state;
}

export default rootReducer;