import React from 'react';
import Search from "../components/Search";
import Lastad from "../components/Lastad";
import Categories from "../components/Categories";

export default class Home extends React.Component {

    render() {
        return (
            <>
                <Search/>
                <Lastad/>
                <Categories/>
            </>
        );
    }
}