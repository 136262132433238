import React from 'react';
import Select from 'react-select'

const Formselect = (props) => {
    const selectStyles = {
        menu: base => ({
            ...base,
            zIndex: 100
        })
    };

    return (
        <div className="form-group floating-label-form-group enter-value">
            <label>{props.label}</label>
            <Select
                styles={selectStyles}
                defaultValue={props.value}
                onChange={props.handleChange(props.name)}
                placeholder={props.placeholder}
                options={props.options}
                />
            {props.error(props.name) &&
                <div className="invalid-feedback d-block">{props.error(props.name)}</div>
            }
        </div>
    );
}

Formselect.defaultProps = {
    name: "field",
    label: "Field",
    placeholder: null,
    value: null,
    options: []
};

export default Formselect;