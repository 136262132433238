import React, { useState} from 'react';
import { useHistory } from 'react-router-dom';
import Icofont from 'react-icofont';
import {useSelector} from "react-redux";
import classlogo from '../assets/img/class-logo.png';
import Subheader from "./Subheader";
import ServerAutoSuggest from "./suggest/ServerAutoSuggest";

const InnerSearch = () => {
    const [keyword, setKeyword] = useState(null);
    const [keywordlocation, setKeywordlocation] = useState(null);
    const history = useHistory();

    const settings = useSelector(
        state => state.settings
    );

    const locations = useSelector(
        state => state.locations
    );

    const setValue = (value) => {
        setKeyword(value);
    }

    const runSearch = () => {
        if (keyword && keyword.length >= 2) {
            history.push("/search/" + encodeURIComponent(keyword) + (keywordlocation?"/"+keywordlocation:""));
        }
    }

    return(
        <>
        <section className="homepage-search-block position-relative pt-2" style={{backgroundColor:"#d90e0e"}}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-7 mx-auto pt-lg-3">
                        <div className="homepage-search-form">
                            <form className="form-noborder" onSubmit={runSearch}>
                                <div className="form-row">
                                    <div className="col-lg-3 col-md-3 col-sm-12 form-group">
                                        <div className="location-dropdown">
                                            <Icofont icon="location-arrow"/>
                                            <select className="custom-select form-control-lg" onChange={e => setKeywordlocation(e.target.value)}>
                                                <option value="0">בחר אזור</option>
                                                {locations.map(location =>
                                                    <option key={location.id} value={location.id}>{location.hebrew}</option>
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-7 col-md-7 col-sm-12 form-group">
                                        <ServerAutoSuggest type="text" onChange={setValue} placeholder={settings.search} className=""/>
                                    </div>
                                    <div className="col-lg-2 col-md-2 col-sm-12 form-group">
                                        <button type="button" onClick={runSearch} className="btn btn-secondary btn-block btn-lg shadow-sm"><Icofont icon="search"/></button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-lg-5 mx-auto text-center">
                        <img onClick={()=>{history.push('/')}} style={{cursor:"pointer"}} src={classlogo} className="img-fluid"/>
                    </div>
                </div>
            </div>
        </section>
        <Subheader/>
        </>
    )
}

export default InnerSearch;
