import React from 'react';
import {Link} from "react-router-dom";

const Category = (props) => {
    return(
        <div className="col-xl-3 col-md-12 mb-4">
            <Link to={"/category/"+props.id}>
                <div className="bg-white p-4 shadow-sm text-center h-100 border-radius">
                    <div className="all-coupon">
                        <div className="row">
                            <div className="col">
                                <h4 className="mt-1 h5 text-gray-900">{props.titlehebrew}</h4>
                                <h6 className="mb-4 mt-3 pb-2 text-secondary font-weight-normal">{props.titleenglish}</h6>
                            </div>
                            {props.image &&
                            <div className="col-auto pt-3">
                                <img src={props.image} style={{width:"50px"}}/>
                            </div>
                            }
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    )
}

export default Category;