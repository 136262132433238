import React from 'react';
import {useSelector} from "react-redux";
import minilogo from '../assets/img/minilogo.png';

const Footer = () => {
    const categories = useSelector(
        state => state.categories
    );

    const logoClick = () => {
        if (navigator.userAgent.indexOf('Mobile/') > 0 ) {
            window.location.href = '//app.hebrewnews.com/';
        }else {
            window.location.href = '//hebrewnews.com/';
        }
    }

    return(
        <div className="header-bg">
            <div className="row justify-content-center">
                <div className="col pt-3 header">
                    <div className="row p-2 justify-content-end">
                        <div className="col-md-8 col-sm-12" style={{borderRight:"solid rgb(50,51,50) 1px"}}>
                            <div className="row">
                                <div className="col-4 text-right">
                                    <a href="https://hebrewnews.com/advertize" target="_blank" className="footer-link rtl mb-2">פרסם אצלנו</a>
                                    <a href="https://hebrewnews.com/aboutus" target="_blank" className="footer-link rtl mb-2">אודותנו</a>
                                    <a href="https://hebrewnews.com/info/5" target="_blank" className="footer-link rtl mb-2">תנאי שימוש</a>
                                    <a href="https://yp.hebrewnews.com/business/new" target="_blank" className="footer-link rtl mb-2"> הוספת עסק למאגר</a>
                                    <a href="https://hebrewnews.com/newsletter" target="_blank" className="footer-link rtl mb-2"> ניוזלטר</a>
                                    <a href="https://hebrewnews.com/contactus" target="_blank" className="footer-link rtl mb-2"> צור קשר</a>
                                    <a href="https://hebrewnews.com/career" target="_blank" className="footer-link rtl"> קריירה</a>
                                </div>
                                <div className="col-4 text-right">
                                    <a onClick={logoClick} href="javascript:void(true);" className="footer-link rtl mb-2 pointer">חדשות ארה"ב</a>
                                    <a href="https://party.hebrewnews.com/" target="_blank" className="footer-link rtl mb-2">מגזין מסיבות</a>
                                    <a href="https://yp.hebrewnews.com/" target="_blank" className="footer-link rtl mb-2">דפי אמריקה</a>
                                </div>
                                <div className="col-4 text-right">
                                    <a href="https://www.nuhomeshowroom.com/" target="_blank" className="footer-link mb-2">Kitchen Remodeling</a>
                                    <a href="https://trusttor.com/" target="_blank" className="footer-link mb-2">Contractors Los Angeles</a>
                                    <a href="https://www.miatessa.com/" target="_blank" className="footer-link mb-2">Modern Cabinets</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12 text-center">
                            <img src={minilogo} onClick={logoClick} className="pointer" style={{width:"200px"}}/>
                            <div className="footer-text">
                                COPYRIGHT © 2021 HEBREW PARTY INC.<br/>
                                ALL RIGHTS RESERVED
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;
