import React from 'react';
import {Link} from "react-router-dom";
import Userclassifieds from "../components/Userclassifieds";

const Account = () => {
    return(
        <>
        <div className="shadow-sm rounded overflow-hidden mb-3">
            <div className="p-4 bg-white">
                <div className="row justify-content-between">
                    <div className="col-auto pt-2">
                        <h5 className="mb-0 text-gray-900">My Classifieds</h5>
                    </div>
                    <div className="col-auto">
                        <Link className="btn btn-primary btn-lg" to="/post">Create classified</Link>
                    </div>
                </div>

            </div>
        </div>
        <div>
            <div className="row equal-cols">
                <Userclassifieds/>
            </div>
        </div>
        </>
    )
}

export default Account;
