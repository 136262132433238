import React, {useEffect, useState} from 'react';
import axios from "axios";
import ClassifiedInList from "../components/ClassifiedInList";

const Search = (props) => {
    const [classifieds, setClassifieds] = useState(null);

    useEffect(() => {
        axios.post(process.env.REACT_APP_API_URL+'/classified/search/',{key:props.match.params.key,location:props.match.params.region}).then((response)=>{
            setClassifieds(response.data.data);
        });
    },[props.match.params.key,props.match.params.region]);

    return(
        <>
            {classifieds &&
            <section className="section-padding homepage-view-offers">
                <div className="container">
                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                        <h1 className="h5 mb-0 text-gray-900">Search</h1>
                    </div>
                    <div className="row">
                        {
                            classifieds.map((classified, index) => {
                                return (
                                    <ClassifiedInList
                                        classified={classified}
                                        columns="4"
                                    />)
                            })
                        }
                        {classifieds.length === 0 &&
                        <div className="col">
                            <div className="row justify-content-center">
                                <div className="col-6 text-center">
                                    <div><span style={{fontSize:"26px"}}>אין תוצאות</span></div>
                                    <div><span style={{fontSize:"20px"}}>We couldn't find any matches for "{props.match.params.key}"</span>
                                    </div>
                                    <div><span style={{fontSize:"16px"}}>Double check your search for any typos or spelling errors - or try different search term</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                    </div>
                </div>
            </section>
            }
        </>
    )
}

export default Search;
