import React, {useState} from 'react';
import {Link, Redirect} from "react-router-dom";
import axios from "axios";
import {login} from "../state/actions";
import {useDispatch, useSelector} from "react-redux";
import AuthHeader from "../components/AuthHeader";

const Signup = () => {
    const [formdata,setFormdata] = useState([]);
    const [formerror,setFormerror] = useState([]);
    const dispatch = useDispatch();

    const user = useSelector(
        state => state.user
    )

    const submitForm = () => {
        let isError = false;
        if (!formdata.name || formdata.name.length < 2 || formdata.name.length > 64) {
            setFormerror(Object.assign({}, formerror, {
                "name": "Invalid name"
            }))
            isError = true
        }

        if (!formdata.email || !(/\S+@\S+\.\S+/.test(formdata.email))) {
            setFormerror(Object.assign({}, formerror, {
                "email": "Invalid email"
            }))
            isError = true
        }

        if (!formdata.password || formdata.password.length < 8 || formdata.password.length > 64) {
            setFormerror(Object.assign({}, formerror, {
                "password": "Invalid password. Min length 8 characters"
            }))
            isError = true
        }

        if (!isError) {
            axios.post(process.env.REACT_APP_API_URL+'/classified/signup',formdata).then((response) => {
                dispatch(login(response.data.data));

                }
            ).catch((error)=>{
                if (error.response.data.message) {
                    if (error.response.data.message.email) {
                        setFormerror(Object.assign({}, formerror, {
                            "email": error.response.data.message.email
                        }))
                    }
                    if (error.response.data.message.password) {
                        setFormerror(Object.assign({}, formerror, {
                            "password": error.response.data.message.password
                        }))
                    }
                    if (error.response.data.message.name) {
                        setFormerror(Object.assign({}, formerror, {
                            "name": error.response.data.message.name
                        }))
                    }
                }
            })
        }
    }

    return(
        <section className="login-main-wrapper">
            {user ?
                <Redirect to="/account" />
            :
                <div className="container-fluid pl-0 pr-0">
                    <div className="row no-gutters">
                        <div className="col-md-12 p-5 bg-white full-height vertical-center">
                            <div className="login-main-left">
                                <div className="text-center mb-2 login-main-left-header pt-0 mr-0">
                                    <AuthHeader/>
                                    <h4 className="mt-3 mb-3">Sign Up</h4>
                                </div>
                                <form>
                                    <div className="form-group floating-label-form-group enter-value">
                                        <label>Enter your name</label>
                                        <input type="text" className={formerror.name?`form-control is-invalid`:`form-control`} placeholder="Name"
                                               onChange={e => {
                                                   setFormdata(Object.assign({}, formdata, {
                                                       "name": e.target.value
                                                   }))
                                                   setFormerror(Object.assign({}, formerror, {
                                                       "name": false
                                                   }))
                                               }
                                               }
                                        />
                                        {formerror.name &&
                                        <div className="invalid-feedback form-error pr-4 mr-2">{formerror.name}</div>
                                        }
                                    </div>
                                    <div className="form-group floating-label-form-group enter-value">
                                        <label>Enter your email</label>
                                        <input type="text" className={formerror.email?`form-control is-invalid`:`form-control`} placeholder="Email"
                                               onChange={e => {
                                                   setFormdata(Object.assign({}, formdata, {
                                                       "email": e.target.value
                                                   }))
                                                   setFormerror(Object.assign({}, formerror, {
                                                       "email": false
                                                   }))
                                               }
                                               }
                                        />
                                        {formerror.email &&
                                        <div className="invalid-feedback form-error pr-4 mr-2">{formerror.email}</div>
                                        }
                                    </div>
                                    <div className="form-group floating-label-form-group enter-value">
                                        <label>Password</label>
                                        <input type="password" className={formerror.password?`form-control is-invalid`:`form-control`} placeholder="Password"
                                               onChange={e => {
                                                   setFormdata(Object.assign({}, formdata, {
                                                       "password": e.target.value
                                                   }))
                                                   setFormerror(Object.assign({}, formerror, {
                                                       "password": false
                                                   }))
                                               }
                                               }
                                        />
                                        {formerror.password &&
                                        <div className="invalid-feedback form-error pr-4 mr-2">{formerror.password}</div>
                                        }
                                    </div>
                                    <div className="mt-4">
                                        <div className="row justify-content-center">
                                            <div className="col-6 pl-2">
                                                <button type="button" className="btn btn-primary btn-block btn-lg" onClick={submitForm}>Sign Up</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <div className="text-center mt-5">
                                    <p className="light-gray">Already have an Account? <Link to="/signin">Sign In</Link></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </section>
    )
}

export default Signup;
