import React, {useEffect} from 'react';
import { Route, Redirect } from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {login} from "../state/actions";
import PrivateLayout from "../components/layout/PrivateLayout";

const PrivateRoute = ({ component: Component, layout: Layout, ...rest }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(login(null))
    },[dispatch]);


    const user = useSelector(
        state => state.user
    )

    return (
        <Route
            {...rest}
            render={(props) =>
                (user ?
                        <PrivateLayout {...rest}>
                            <Component {...props} />
                        </PrivateLayout>
                        :
                        <Redirect to="/signin" />
                )
            }
        />
    );
};

export default PrivateRoute;