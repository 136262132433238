import React from 'react';
import Autosuggest from 'react-autosuggest';
import './autosuggest.css';

class ServerAutoSuggest extends React.Component {
    constructor() {
        super();

        this.state = {
            value: '',
            suggestions: []
        };
    }

    // Filter logic
    getSuggestions = async (value) => {
        const inputValue = value.trim().toLowerCase();
        let response = await fetch("https://api.hebrewnews.com/classified/suggest/" + inputValue);
        let data = await response.json()
        return data;
    };

    // Trigger suggestions
    getSuggestionValue = suggestion => suggestion.titlehebrew;

    // Render Each Option
    renderSuggestion = suggestion => (
        <span className="row">
            <div className="col-3">{suggestion.image && <img src={suggestion.image} className="img-fluid"/>}</div>
            <div className="col-9 text-right">
                {suggestion.titlehebrew}
            </div>
        </span>
    );

    // OnChange event handler
    onChange = (event, { newValue }) => {
        this.setState({
            value: newValue
        });
        this.props.onChange(newValue);
    };

    // Suggestion rerender when user types
    onSuggestionsFetchRequested = ({ value }) => {
        this.getSuggestions(value)
            .then(data => {
                if (data.Error) {
                    this.setState({
                        suggestions: []
                    });
                } else {
                    this.setState({
                        suggestions: data.data
                    });
                }
            })
    };

    // Triggered on clear
    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    render() {
        const { value, suggestions } = this.state;

        // Option props
        const inputProps = {
            value,
            onChange: this.onChange
        };

        // Adding AutoSuggest component
        return (
            <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                getSuggestionValue={this.getSuggestionValue}
                renderSuggestion={this.renderSuggestion}
                inputProps={inputProps}
                theme={{
                    container:                'react-autosuggest__container',
                    containerOpen:            'react-autosuggest__container--open',
                    inputOpen:                'react-autosuggest__input--open',
                    inputFocused:             'react-autosuggest__input--focused',
                    suggestionsContainer:     'react-autosuggest__suggestions-container',
                    suggestionsContainerOpen: 'react-autosuggest__suggestions-container--open',
                    suggestionsList:          'react-autosuggest__suggestions-list',
                    suggestion:               'react-autosuggest__suggestion',
                    suggestionFirst:          'react-autosuggest__suggestion--first',
                    suggestionHighlighted:    'react-autosuggest__suggestion--highlighted',
                    sectionContainer:         'react-autosuggest__section-container',
                    sectionContainerFirst:    'react-autosuggest__section-container--first',
                    sectionTitle:             'react-autosuggest__section-title',
                    input:'form-control border-0 form-control-lg shadow-sm rtl'
                }}
            />
        );
    }
}

export default ServerAutoSuggest;
