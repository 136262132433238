import React, {useEffect, useState} from 'react';
import UserClassified from "../components/UserClassified";
import HTTP from "./utils/HTTP";

const Userclassifieds = () => {
    const [classifieds, setClassifieds] = useState(null);

    useEffect(() => {
        HTTP.get('/classified/userclassifieds').then((response)=>{
            setClassifieds(response.data.data);
        });
    },[]);

    return(
        <>
            {classifieds &&
                classifieds.map((classified, index) => {
                    return (
                        <UserClassified
                            classified={classified}
                            columns="12"
                        />)
                })
            }
        </>
    )
}

export default Userclassifieds;