import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import {useDispatch, useSelector} from "react-redux";
import {logout} from "../../state/actions";
import {Link} from "react-router-dom";

const PrivateLayout = (props) => {
    const { children } = props;
    const dispatch = useDispatch();

    const user = useSelector(
        state => state.user
    )

    const runLogout = () => {
        dispatch(logout())
    }

    return (
        <div id="wrapper">
            <div id="content-wrapper">
                <div id="content">
                    <Header/>
                    <div className="contentOutFooter">
                    <section className="user-panel-body py-5">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-3 col-sm-4">
                                    <div className="user-panel-body-left">
                                        <div className="bg-white rounded p-4 mb-4 text-center shadow-sm">
                                            <Link to="/account"><h6 className="text-black mb-2 text-gray-900">{user.name}</h6></Link>
                                            <p className="mb-3">{user.email}</p>
                                            <button type="button" onClick={runLogout} className="btn btn-primary btn-block"><i className="icofont-logout"/>
                                                Logout
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-9 col-sm-8">
                                    <div className="user-panel-body-right">
                                        <div id="mc" className="user-panel-tab-view mb-4">
                                            {children}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    </div>
                    <Footer/>
                </div>
            </div>
        </div>
    );
};

export default PrivateLayout;
