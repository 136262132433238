import React, {useEffect, useState} from 'react';
import logo from '../assets/img/yplogo.png';
import Icofont from 'react-icofont';
import axios from "axios";
import Category from "./Category";

const Yellowpages = () => {
    const [keyword,setKeyword] = useState(null)
    const [listings,setListings] = useState(null)

    useEffect(() => {
        runSearch()
    },[keyword]);

    const runSearch = () => {
        axios.get(process.env.REACT_APP_API_URL+'/listing/widget'+(keyword?('/'+keyword):'')).then((response)=>{
            setListings(response.data.data);
        });
    }

    return(
        <>
            {listings &&
            <section className="section-padding">
                <div className="container">
                    <div className="text-right mb-2">
                        <h1 className="h5 mb-0 text-black font-weight-bold">בעלי מקצוע</h1>
                    </div>
                    <div className="row">
                        <div className="col-12 m-0 p-0">
                            <div className="ypHeaderCenter text-center p-3 simpleLink">
                                <div className="d-none d-md-block">
                                    <div className="row">
                                        <div className="col-md-3 col-sm-12 order-md-1 order-sm-3">
                                            <a href="https://yp.hebrewnews.com/listing/add" target="_blank">הוסף בית עסק<b>+</b></a>
                                        </div>
                                        <div className="col-md-6 col-sm-12 order-md-2 order-sm-2">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text pointer" onClick={runSearch}><Icofont icon="search"/></span>
                                                </div>
                                                <input type="text" onChange={e => setKeyword(e.target.value)} className="form-control" placeholder="חפש בעברית או אנגלית" aria-label="חפש בעברית או אנגלית"/>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-12 order-md-3 order-sm-1">
                                            <a href="https://yp.hebrewnews.com" target="_blank"><img src={logo} style={{width: "150px"}}/></a>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-block d-md-none">
                                    <div className="row">
                                        <div className="col-sm-12 mb-3">
                                            <a href="https://yp.hebrewnews.com" target="_blank"><img src={logo} style={{width: "150px"}}/></a>
                                        </div>
                                        <div className="col-sm-12 mb-3">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text pointer"
                                                          onClick={runSearch}><Icofont icon="search"/></span>
                                                </div>
                                                <input type="text" onChange={e => setKeyword(e.target.value)} className="form-control" placeholder="חפש בעברית או אנגלית" aria-label="חפש בעברית או אנגלית"/>
                                            </div>
                                        </div>
                                        <div className="col-sm-12">
                                            <a href="https://yp.hebrewnews.com/listing/add" target="_blank">הוסף בית עסק<b>+</b></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="ypBody text-center p-3 simpleLink">
                                <div className="row">
                                    {
                                        listings.map((listing, index) => {
                                            let listingImageStyle = {

                                            }

                                            if (listing.images.length) {
                                                listingImageStyle.background = 'url(' + listing.images[0].url + ')';
                                            }

                                            return (
                                                <>
                                                    {listing.images.length>0 &&
                                                    <div className="col-md-4 col-sm-12">
                                                        <div className="row m-1 p-2"
                                                             style={{borderTop: "solid 1px black", cursor: "pointer"}}>
                                                            <div className="col-8 p-0">
                                                                <a href={"https://yp.hebrewnews.com/listing/index/" + listing.listingID}
                                                                   className="listingLink" target="_blank">
                                                                    <div className="listingNameEnglish">{listing.nameEnglish}</div>
                                                                    <div className="listingNameHebrew">{listing.nameHebrew}</div>
                                                                    <div className="listingPhone">{listing.phone}</div>
                                                                </a>
                                                            </div>
                                                            <div className="col-4 text-center">
                                                                <a href={"https://yp.hebrewnews.com/listing/index/" + listing.listingID} target="_blank">
                                                                    <div className="ypImage" style={listingImageStyle}/>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    }
                                                </>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            }
        </>
    )
}

export default Yellowpages;