import React, {useEffect, useState} from 'react';
import Formselect from "./Formselect";
import HTTP from "../utils/HTTP";

const Categoryselect = (props) => {

    const [categories,setCategories] = useState([])

    useEffect(() => {
        HTTP.get('/classified/form/category').then((response) => setCategories(response.data.data));
    }, []);

    return (
        <Formselect
            label={props.label}
            name={props.name}
            error={props.error}
            placeholder={props.placeholder}
            handleChange={props.handleChange}
            options={categories}
            />
    );
}

export default Categoryselect;