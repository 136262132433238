import {SET_SETTINGS, SET_LOCATIONS, SET_CATEGORIES, SET_USER, REMOVE_USER} from "../../constants/actions";
import axios from "axios";

export function getSettings() {
    return function (dispatch) {
        axios.get(process.env.REACT_APP_API_URL+'/classified/mainpage')
            .then((response) => dispatch({
                type: SET_SETTINGS,
                payload: response.data.data
            }))
    }
}

export function getLocations() {
    return function (dispatch) {
        axios.get(process.env.REACT_APP_API_URL+'/classified/locations')
            .then((response) => dispatch({
                type: SET_LOCATIONS,
                payload: response.data.data
            }))
    }
}

export function getCategories() {
    return function (dispatch) {
        axios.get(process.env.REACT_APP_API_URL+'/classified/categories')
            .then((response) => dispatch({
                type: SET_CATEGORIES,
                payload: response.data.data
            }))
    }
}

export function login(user) {
    return {
        type: SET_USER,
        payload: user
    }
}

export function logout() {
    return {
        type: REMOVE_USER
    }
}