import React from "react";
import {useSelector} from "react-redux";

const AuthLayout = (props) => {
    const { children } = props;

    const settings = useSelector(
        state => state.settings
    );

    let headerBgStyles = {};

    if (settings) {
        headerBgStyles = {
            background : 'url(' + settings.authbgimage + ')',
        }
    }else {
        headerBgStyles = {
        }
    }

    return (
        <div id="wrapper">
            <div id="content-wrapper">
                <div id="content">
                    <div className="row">
                        <div className="col-md-4 col-sm-12 p-0">
                        {children}
                        </div>
                        <div className="mobileHide col-md-8 col-sm-12 homeBgImage" style={ headerBgStyles }>
                            <div className="text-center w-100" style={{position:"absolute",top:"40%"}}>
                                <span style={{color:"white",direction:"rtl",fontSize:"30px"}}>{settings.authtext}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AuthLayout;
