import React, {useEffect, useState} from 'react';
import Formselect from "./Formselect";
import HTTP from "../utils/HTTP";

const Jobtypeselect = (props) => {

    const [types,setTypes] = useState([])

    useEffect(() => {
        HTTP.get('/classified/form/jobtype').then((response) => setTypes(response.data.data));
    }, []);

    return (
        <Formselect
            label={props.label}
            name={props.name}
            error={props.error}
            placeholder={props.placeholder}
            handleChange={props.handleChange}
            options={types}
        />
    );
}

export default Jobtypeselect;