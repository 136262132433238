import axios from "axios";

const HTTP = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 60000,
});

HTTP.interceptors.request.use(
    (config) => {
        config.headers.authorization = `Bearer ${localStorage.getItem('jwt')}`;
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default HTTP;