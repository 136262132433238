import React, { useEffect } from "react";
import Routes from "./routes/index";
import { useLocation } from "react-router-dom";
import './App.css';
import { useDispatch } from 'react-redux'
import {getCategories, getLocations, getSettings} from "./state/actions";
import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';

const App = () => {

  const {pathname} = useLocation();
  const dispatch = useDispatch();

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE);

  useEffect(() => {
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
    } catch (error) {
      window.scrollTo(0, 0);
    }
  }, [pathname]);

  useEffect(() => {
    dispatch(getSettings())
    dispatch(getLocations())
    dispatch(getCategories())
  },[dispatch]);

  return (
      <Elements stripe={stripePromise}>
        <div className="App">
          <Routes/>
        </div>
      </Elements>
  );
}

export default App;
