import React from "react";
import { Switch } from "react-router-dom";
import NotFoundPage from "../components/layout/NotFoundPage";
import PublicRoute from "./PublicRoute";
import Home from "../pages/Home";
import Category from "../pages/Category";
import Classified from "../pages/Classified";
import 'antd/dist/antd.css';
import Search from "../pages/Search";
import Login from "../pages/Login";
import PrivateRoute from "./PrivateRoute";
import Account from "../pages/Account";
import Post from "../pages/Post";
import Signup from "../pages/Signup";
import Categories from "../pages/Categories";
import Sponsored from "../pages/Sponsored";
import AuthRoute from "./AuthRoute";

const Routes = () => {
    return (
        <Switch>
            <PublicRoute
                exact
                path="/"
                component={() => <Home/>}
            />
            <PublicRoute
                exact
                path="/category/:id"
                component={Category}
            />
            <PublicRoute
                exact
                path="/classified/:id"
                component={Classified}
            />
            <PublicRoute
                exact
                path="/search/:key/:region?"
                component={Search}
            />
            <AuthRoute
                exact
                path="/signin"
                component={Login}
            />
            <AuthRoute
                exact
                path="/signup"
                component={Signup}
            />
            <PublicRoute
                exact
                path="/categories"
                component={Categories}
            />
            <PrivateRoute
                exact
                path="/account"
                component={Account}
            />
                <PrivateRoute
                    exact
                    path="/post"
                    component={Post}
                />
            <PrivateRoute
                exact
                path="/account/sponsored/:id"
                component={Sponsored}
            />
            <PublicRoute path="*" component={NotFoundPage} fixedPage pageCenter />
        </Switch>
    );
};

export default Routes;
