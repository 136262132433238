import React from 'react';

const Formtextarea = (props) => {
    return (
        <div className="form-group floating-label-form-group enter-value">
            <label>{props.label}</label>
            <textarea
                className={props.error(props.name)?`form-control is-invalid`:`form-control`}
                placeholder={props.placeholder}
                onChange={props.handleChange(props.name)}
            >{props.value}</textarea>
            {props.error(props.name) &&
            <div className="invalid-feedback form-error pr-4 mr-2">{props.error(props.name)}</div>
            }
        </div>
    );
}

Formtextarea.defaultProps = {
    name: "field",
    label: "Field",
    placeholder: null,
    value: null
};

export default Formtextarea;