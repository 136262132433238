import React from 'react';
import {Link} from "react-router-dom";

const Subheader = () => {
    return(
        <div className="subheader">
            <div className="row justify-content-center">
                <div className="col header">
                    <div className="row p-2 justify-content-end">
                        <div className="col-auto text-right">
                            <Link to="/post">
                                <span className="subheader-link">פרסום מודעה חדשה</span>
                            </Link>
                        </div>
                        <div className="col-auto text-right">
                            <Link to="/account">
                                <span className="subheader-link">התחברות</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
}

export default Subheader;