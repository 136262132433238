import React from 'react';
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import minilogo from '../assets/img/hnlogo.png';

const AuthHeader = () => {
    const history = useHistory();

    const settings = useSelector(
        state => state.settings
    );

    return (
        <>
            <img src={minilogo} onClick={()=>{history.push('/');}} className="pointer" style={{width:"200px"}}/>
            <h1 className="mb-3 text-shadow text-gray-900 font-weight-bold"><span
                style={{color:"#d90e0e"}}>{settings.titletext1}</span>
            </h1>
        </>
    )

}

export default AuthHeader;
