import React from 'react';
import Category from "./Category";
import {useSelector} from "react-redux";

const Categories = () => {
    const categories = useSelector(
        state => state.categories
    );

    return(
        <>
            {categories &&
            <section className="section-padding">
                <div className="container">
                    <div className="text-right mb-2">
                        <h1 className="h5 mb-0 text-black font-weight-bold">קטגוריות פופולריות</h1>
                    </div>
                    <div className="row pt-lg-4 justify-content-end">
                        {
                            categories.map((category, index) => {
                                return (
                                    <Category
                                        titlehebrew={category.titlehebrew}
                                        titleenglish={category.titleenglish}
                                        id={category.id}
                                        last={category.last}
                                        image={category.image?category.image:null}
                                    />)
                            })
                        }
                    </div>
                </div>
            </section>
            }
            </>
    )
}

export default Categories;