import React from "react";
import Header from "../Header";
import Footer from "../Footer";

const PublicLayout = (props) => {
    const { children } = props;

    return (
        <div id="wrapper">
            <div id="content-wrapper">
                <div id="content">
                    <Header/>
                    <div className="contentOutFooter">
                    {children}
                    </div>
                    <Footer/>
                </div>
            </div>
        </div>
    );
};

export default PublicLayout;
