import React,{ useState,useEffect } from 'react';
import axios from 'axios';
import ClassifiedInList from "./ClassifiedInList";

const Lastad = () => {
    const [last, setLast] = useState(null);
    const [showmore, setShowmore] = useState(false);

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL+'/classified/last').then((response)=>{
            if (response.data.data.length < 40) {
                setShowmore(true)
            }
            setLast(response.data.data);
        });
    },[]);

    const showMore = () => {
        axios.get(process.env.REACT_APP_API_URL+'/classified/last/200').then((response)=>{
            setLast(response.data.data);
        });
        setShowmore(false)
    }

    return(
        <>
        {last &&
            <section className="section-padding homepage-view-offers">
                <div className="container">
                    <div className="row equal-cols">
                        {
                            last.map((classified, index) => {
                                return (
                                <ClassifiedInList
                                    classified={classified}
                                />)
                            })
                        }
                    </div>
                    {showmore &&
                    <div className="row justify-content-center">
                        <div className="col-auto">
                            <a className="color-red" onClick={showMore}>Show more</a>
                        </div>
                    </div>
                    }
                </div>
            </section>
        }
        </>
    )
}

export default Lastad;
