import React, {useState} from 'react';
import Forminput from "../components/formelements/Forminput";
import Formtextarea from "../components/formelements/Formtextarea";
import Categoryselect from "../components/formelements/Categoryselect";
import Locationselect from "../components/formelements/Locationselect";
import ImageUploader from 'react-images-upload';
import Jobtypeselect from "../components/formelements/Jobtypeselect";
import HTTP from "../components/utils/HTTP";
import { useHistory } from "react-router-dom";
import ClassifiedInList from "../components/ClassifiedInList";

const Post = () => {
    const history = useHistory();

    const [formdata,setFormdata] = useState([])
    const [formerror,setFormerror] = useState([])
    const [pictures,setPictures] = useState([])
    const [uploading,setUploading] = useState(false)

    const handleChange = input => e => {
        setData(input,e.target.value)
        setError(input,null)
    }

    const handleUpload = pictures => {
        setPictures(pictures);
    }

    const handleSelectChange = input => e => {
        setData(input,e.value)
        setError(input,null)
    }

    const handleMultiSelectChange = input => e => {
        let value = [];
        e.map((location, index) => {
            value.push(location.value)
        })
        setData(input,value)
        setError(input,null)
    }

    const setData = (name,value) => {
        setFormdata(Object.assign({}, formdata, {
            [name]: value
        }))
    }

    const setError = (field,error) => {
        setFormerror(Object.assign({}, formerror, {
            [field]: error
        }))
    }

    const error = (field) => {
        return formerror[field]
    }

    const submitClassified = () => {
        let isError = false
        let errors = [];

        if (!formdata.category) {
            errors.category = 'Category is required field';
            isError = true
        }

        if (!formdata.title || !formdata.title.length) {
            errors.title = 'Title is required field';
            isError = true
        }

        if (!formdata.description || !formdata.description.length) {
            errors.description = 'Description is required field';
            isError = true
        }

        if (isError) {
            setFormerror(errors)
        }else {
            let formData = new FormData();

            if (pictures) {
                pictures.forEach(file => {
                    formData.append(file.name, file);
                });
            }

            if (formdata.category) {
                formData.append('category', formdata.category);
            }
            if (formdata.location) {
                formData.append('location', formdata.location);
            }
            if (formdata.title) {
                formData.append('title', formdata.title);
            }
            if (formdata.subtitle) {
                formData.append('subtitle', formdata.subtitle);
            }
            if (formdata.companyname) {
                formData.append('companyname', formdata.companyname);
            }
            if (formdata.description) {
                formData.append('description', formdata.description);
            }
            if (formdata.jobdescription) {
                formData.append('jobdescription', formdata.jobdescription);
            }
            if (formdata.requirements) {
                formData.append('requirements', formdata.requirements);
            }
            if (formdata.jobtype) {
                formData.append('jobtype', formdata.jobtype);
            }
            if (formdata.url) {
                formData.append('url', formdata.url);
            }
            if (formdata.contactname) {
                formData.append('contactname', formdata.contactname);
            }
            if (formdata.contactphone) {
                formData.append('contactphone', formdata.contactphone);
            }
            if (formdata.contactemail) {
                formData.append('contactemail', formdata.contactemail);
            }
            if (formdata.tags) {
                formData.append('tags', formdata.tags);
            }

            setUploading(true)
            HTTP.post('/classified/post', formData).then((response) => history.push("/account"));
        }
    }

    return(
        <>
            <div className="shadow-sm rounded overflow-hidden mb-3">
                <div className="p-4 bg-white">
                    <div className="row justify-content-between">
                        <div className="col-auto pt-2">
                            <h5 className="mb-0 text-gray-900">New Classifieds</h5>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="p-4 bg-white shadow-sm rounded">
                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <Categoryselect
                                label="Category"
                                name="category"
                                error={error}
                                handleChange={handleSelectChange}
                            />
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <Locationselect
                                label="Location"
                                name="location"
                                error={error}
                                handleChange={handleMultiSelectChange}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <Forminput
                                label="Title"
                                name="title"
                                error={error}
                                handleChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <Forminput
                                label="Subtitle"
                                name="subtitle"
                                error={error}
                                handleChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <Forminput
                                label="Company name"
                                name="companyname"
                                error={error}
                                handleChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <Formtextarea
                                label="Description"
                                name="description"
                                error={error}
                                handleChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <Formtextarea
                                label="Job description"
                                name="jobdescription"
                                error={error}
                                handleChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <Formtextarea
                                label="Requirements"
                                name="requirements"
                                error={error}
                                handleChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <Jobtypeselect
                                label="Type"
                                name="jobtype"
                                error={error}
                                handleChange={handleSelectChange}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <Forminput
                                label="URL"
                                name="url"
                                error={error}
                                handleChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <Forminput
                                label="Search tags"
                                name="tags"
                                error={error}
                                handleChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <Forminput
                                label="Contact name"
                                name="contactname"
                                error={error}
                                handleChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <Forminput
                                label="Contact phone"
                                name="contactphone"
                                error={error}
                                handleChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <Forminput
                                label="Contact email"
                                name="contactemail"
                                error={error}
                                handleChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <ImageUploader
                                withIcon={true}
                                withPreview={true}
                                buttonText='Choose images'
                                onChange={handleUpload}
                                imgExtension={['.jpg', '.gif', '.png', '.gif']}
                                maxFileSize={5242880}
                            />
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-auto">
                            {uploading ?
                                <a className="btn btn-success btn-lg text-white">
                                    <div className="spinner-border spinner-border-sm" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div></a>
                                :
                                <a className="btn btn-success btn-lg text-white" onClick={submitClassified}>Post</a>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Post;
