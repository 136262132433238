import React from "react";

const Gallery = (props) => {

    return (
        <>
            {props.photos &&
                <div className="row">
                    {
                        props.photos.map((photo,index) => {
                            return (
                            <div className="col-sm-12" key={photo}>
                                <img src={photo} className="img-fluid item-img" alt="classified"/>
                            </div>
                            )
                        })
                    }
                </div>
            }
        </>
    );
}

export default Gallery;