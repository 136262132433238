import React, { useState} from 'react';
import { useHistory } from 'react-router-dom';
import Icofont from 'react-icofont';
import { useSelector } from 'react-redux'
import Subheader from "./Subheader";
import ServerAutoSuggest from "./suggest/ServerAutoSuggest";

const Search = () => {
    const [keyword, setKeyword] = useState(null);
    const [keywordlocation, setKeywordlocation] = useState(null);
    const history = useHistory();

    const settings = useSelector(
        state => state.settings
    );

    const locations = useSelector(
        state => state.locations
    );

    let headerBgStyles = {};

    const runSearch = () => {
        if (keyword && keyword.length >= 2) {
            history.push("/search/" + encodeURIComponent(keyword) + (keywordlocation?"/"+keywordlocation:""));
        }
    }

    const setValue = (value) => {
        setKeyword(value);
    }

    if (settings) {
        headerBgStyles = {
            background : 'url(' + settings.bgimage + ')'
        }
    }else {
        headerBgStyles = {
        }
    }

    return(
        <>
        <section className="section-padding homepage-search-block position-relative homeBgImage" style={ headerBgStyles }>
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 mx-auto pt-lg-5">
                        <div className="homepage-search-title text-center">
                            <h1 className="mb-3 text-shadow text-gray-900 font-weight-bold"><span
                                style={{color: settings.titletext1color ? settings.titletext1color : 'white'}}>{settings.titletext1}</span>
                            </h1>
                            <h5 className="mb-5 text-shadow text-gray-800 font-weight-normal"><span
                                style={{color: settings.titletext2color ? settings.titletext2color : 'white'}}>{settings.titletext2}</span>
                            </h5>
                        </div>
                        <div className="homepage-search-form">
                            <form className="form-noborder" onSubmit={runSearch}>
                                <div className="form-row">
                                    <div className="col-lg-3 col-md-3 col-sm-12 form-group">
                                        <div className="location-dropdown">
                                            <Icofont icon="location-arrow"/>
                                            <select className="custom-select form-control-lg"
                                                    onChange={e => setKeywordlocation(e.target.value)}>
                                                <option value="0">בחר אזור</option>
                                                {locations.map(location =>
                                                    <option key={location.id}
                                                            value={location.id}>{location.hebrew}</option>
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-7 col-md-7 col-sm-12 form-group">
                                        <ServerAutoSuggest type="text" onChange={setValue}
                                               placeholder={settings.search}
                                               className="form-control border-0 form-control-lg shadow-sm rtl"/>
                                    </div>
                                    <div className="col-lg-2 col-md-2 col-sm-12 form-group">
                                        <button type="button" onClick={runSearch}
                                                className="btn btn-primary btn-block btn-lg btn-gradient shadow-sm">
                                            <Icofont icon="search"/></button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Subheader/>
        </>
    )
}

export default Search;
