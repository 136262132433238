import React from 'react';
import CategoriesList from "../components/Categories";

export default class Categories extends React.Component {
    render() {
        return (
            <>
                <CategoriesList/>
            </>
        );
    }
}