import React, {useEffect, useState} from 'react';
import axios from "axios";
import Gallery from "../components/Gallery";
import MetaTags from 'react-meta-tags';
import {
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    FacebookIcon, EmailIcon, LinkedinIcon, TwitterIcon
} from "react-share";
import {Link} from "react-router-dom";
import InnerSearch from "../components/InnerSearch";
import Categories from "../components/Categories";
import Yellowpages from "../components/Yellowpages";
import ClassifiedInList from "../components/ClassifiedInList";

const nl2br = require('react-nl2br');

const Classified = (props) => {
    const [classifieditem, setClassifieditem] = useState(null);

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL+'/classified/get/'+props.match.params.id).then((response)=>{
            setClassifieditem(response.data.data);
        });
    },[props.match.params.id]);

    return(
        <>
            <InnerSearch/>
            {classifieditem &&
            <section className="section-padding homepage-view-offers">
                <MetaTags>
                    <title>{classifieditem.titlehebrew} - Hebrewnews.com הלוח הגדול</title>
                    <meta name="description" content={classifieditem.titlehebrew} />
                    <meta property="og:title" content={classifieditem.titlehebrew} />
                    <meta property="og:image" content={classifieditem.image} />
                </MetaTags>
                <div className="container">
                    <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to={"/"}>Home</Link></li>
                            {classifieditem.category &&
                            <li className="breadcrumb-item"><Link to={"/category/"+classifieditem.categoryid}><span className="rtl">{classifieditem.category.titlehebrew}</span></Link></li>
                            }
                            <li className="breadcrumb-item active" aria-current="page"><span className="rtl">{classifieditem.titlehebrew}</span></li>
                        </ol>
                    </nav>
                    <div className="bg-white shadow-sm rounded p-4 mb-4">
                        <div className="row">
                            <div className="col-md-auto col-sm-12 p-1">
                                {classifieditem.contactphone &&
                                    <h3>{classifieditem.contactphone}</h3>
                                }
                                <EmailShareButton className="sharebutton p-1" url={window.location.href}><EmailIcon size={32} round /></EmailShareButton>
                                <FacebookShareButton className="sharebutton p-1" url={window.location.href}><FacebookIcon size={32} round /></FacebookShareButton>
                                <LinkedinShareButton className="sharebutton p-1" url={window.location.href}><LinkedinIcon size={32} round /></LinkedinShareButton>
                                <TwitterShareButton className="sharebutton p-1" url={window.location.href}><TwitterIcon size={32} round /></TwitterShareButton>
                            </div>
                            <div className="col-md col-sm-12 text-right p-1" style={{fontSize:"18px"}}>
                                <h5 className="mb-1 rtl text-gray-900">{classifieditem.titlehebrew}</h5>
                                <span className="mb-4 rtl text-gray-500">{classifieditem.titleenglish}</span><br/>
                                {classifieditem.companyname &&
                                    <span className="mb-1 rtl text-gray-900">{classifieditem.companyname}</span>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="bg-white shadow-sm rounded p-4 mb-4">
                        <div className="row">
                            {classifieditem.images &&
                            <div className="col-md-6 col-sm-12 p-1">
                                <Gallery
                                    photos={classifieditem.images}
                                />
                            </div>
                            }
                            <div className={classifieditem.images?"col-md-6 col-sm-12 p-1 text-right":"col-sm-12 p-1 text-right"}>
                                <ul className="list-group list-group-horizontal justify-content-end mt-2 mb-2">
                                    {classifieditem.jobtype &&
                                    <li className="list-group-item bgRed m-1">{classifieditem.jobtype}</li>
                                    }
                                    {classifieditem.location &&
                                        classifieditem.location.map((location, index) => {
                                            return (
                                                <li className="list-group-item bgRed m-1">{location.title}</li>)
                                        })
                                    }
                                </ul>
                                <span className="rtl" style={{fontSize:"18px"}}>{nl2br(classifieditem.content)}</span>
                                {classifieditem.jobdescription &&
                                    <div className="mt-4" style={{fontSize:"18px"}}>
                                        <div className="rtl">{nl2br(classifieditem.jobdescription)}</div>
                                    </div>
                                }
                                {classifieditem.jobrequirements &&
                                <div className="mt-4" style={{fontSize:"18px"}}>
                                    <div className="rtl">{nl2br(classifieditem.jobrequirements)}</div>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            }
            <Categories/>
            <Yellowpages/>
        </>
    )
}

export default Classified;
