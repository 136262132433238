import React, {useEffect, useState} from 'react';
import axios from "axios";
import ClassifiedInList from "../components/ClassifiedInList";

const Category = (props) => {
    const [classifieds, setClassifieds] = useState(null);

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL+'/classified/bycategory/'+props.match.params.id).then((response)=>{
            setClassifieds(response.data.data);
        });
    },[props.match.params.id]);

    return(
        <>
            {classifieds &&
            <section className="section-padding homepage-view-offers">
                <div className="container">
                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                        <h1 className="h5 mb-0 text-gray-900">Last classifieds</h1>
                    </div>
                    <div className="row">
                        {
                            classifieds.map((classified, index) => {
                                return (
                                    <ClassifiedInList
                                        classified={classified}
                                        columns="3"
                                    />)
                            })
                        }
                    </div>
                </div>
            </section>
            }
        </>
    )
}

export default Category;