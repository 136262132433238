import React from "react";

import { Route } from "react-router-dom";

import PublicLayout from "../components/layout/PublicLayout";

const PublicRoute = ({ component: Component, layout: Layout, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) =>
                <PublicLayout {...rest}>
                    <Component {...props} />
                </PublicLayout>
            }
        />
    );
};

export default PublicRoute;
